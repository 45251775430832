import {TenantDocument, type TenantQuery} from '@graphql/graphql';

export async function useTenant() {
  const tenant = useState<NonNullable<TenantQuery['tenant']>>();

  if (!tenant.value) {
    const {data} = await useAsyncQuery<TenantQuery>(TenantDocument);

    tenant.value = data.value.tenant!;
  }

  return {
    tenant: readonly(tenant),
  };
}
